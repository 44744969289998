import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const FooterContainer = styled.footer`
    background: #000000;
    height: 25vh;
    z-index: 5;
    overflow: hidden;

    @media screen and (max-height: 1060px) {
        height: 30vh;
    }

    @media screen and (max-height: 900px) {
        height: 40vh;
    }

    @media screen and (max-height: 665px) {
        height: 50vh;
    }

    @media screen and (max-height: 532px) {
        height: 60vh;
    }

    @media screen and (max-height: 445px) {
        height: 70vh;
    }

    @media screen and (max-height: 380px) {
        height: 100vh;
    }
    
`

export const FooterWrap = styled.div`
    padding: 1rem 24px;
    max-width: 1400px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: block;
`

export const FooterLogo = styled(LinkR)`
    cursor: pointer;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
`

export const FooterIMG = styled.img`
    height: 90px;
    width: auto;

    @media screen and (max-width: 480px) {
        height: 75px;
    }
`

export const Slogan = styled.h1`
    color: #d3d3d3;
    font-size: 1rem;
    font-weight: normal;
    margin-top: .5rem;

    @media screen and (max-width: 480px) {
        font-size: 0.8rem;
    }
`

export const TextH2 = styled.h2`
    color: #d3d3d3;
    font-size: 0.75rem;
    font-weight: normal;
    margin-top: .3rem;
`

export const Legal = styled.div`
    padding-top: 2rem;
    padding-bottom: 30px;

    @media screen and (max-width: 480px) {
        padding-top: 1.2rem;
    }
`

export const LegalLink = styled(LinkR)`
    text-decoration: none;
    color: #2f8eed;
`

export const SocialIcons = styled.div`
    display: flex;
	justify-content: space-between;
	align-items: center;
    margin: 0 auto;    
    padding-bottom: 30px;
	width: 180px;
`

export const SocialIconLink = styled.a`
	color: #f0f0f0;
	font-size: 24px;
	padding: 2px;
`