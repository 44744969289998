import React from 'react';
import './App.css';
import Home from './pages/index';
import News from './pages/news';
import About from './pages/about';
import Contact from './pages/contact';
import Story from './pages/story';
import Industry from './pages/industry';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<News />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/story' element={<Story />} />
        <Route path='/industry' element={<Industry />} />
      </Routes>
    </Router>
  );
}

export default App;
